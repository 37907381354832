.dashboard-client-link {
	font-weight: bold;
	font-size: 14px;
}

.dashboard-navigation-title {
	font-size: 20px;
	font-weight: bold;
	color: #4a4a4a;
	margin-bottom: 26px;

	i {
		font-weight: normal;
	}
}

.hidden-panel {
  cursor: default;
	display: none;
	position: relative;
	width: 100%;
}

.panel-toggle {
	cursor: pointer;
}

.event-list-types {
	position: relative;
	float: left;
	width: auto;

	span {
		margin-right: 8px;
		margin-left: 10px;
	}
}

.group-filters, .event-filters, .more-filter {
  display: inline;
  float: left;
}

.dashboard-page-container {

	h3 {
		margin: 5;
	}

	.input-group {
		width: 100%;
	}

	#submit-account-btn {
		margin-top: 25px;
	}

	.well {

		.dashboard-title {
			pointer-events: none;
			font-size: 22px;
			font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
			font-weight: 300;
			margin: 10px;

			span {
				font-size: 18px;
			}
		}	

		.dashboard-small-title {
			font-size: 15px;
		}


		.fa-list, .fa-calendar {
			font-size: 30px;
			margin: 10px;
			color: #0275d8;

			&:hover {
				opacity: 0.75;
			}
		}

		.fa-plus {
			font-size: 30px;
		  color: #000;
		  cursor: pointer;
		  padding: 10px;

		  &:hover {
		  	opacity: 0.75;;
		  }
		}
	}

	.user-deets {
		position: relative;
		float: left;
		width: 100%;

		strong {
			width: 90px;
  	  float: left;
		}
	}


	.event-counter {
		position: relative;
		float: left;
	}

	.search-title {
		font-size: 24px;
	}
}

.fa-close {
	position: relative;
	right: -8px;
	top: -18px;
	font-size: 20px;
	opacity: 1;
	cursor: pointer;
	z-index: 1000;
}

.account-progress  {

	.account-step-step p {
    margin-top: 10px;    
	}

	.account-step-row {
    display: table-row;
	}

	.account-step {
    display: table;     
    width: 100%;
    position: relative;
	}

	.account-step-row:before {
    top: 46px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 5px;
    background-color: #1c9ed9;
    z-order: 0;
	}

	.account-step-step {    
    display: table-cell;
    text-align: center;
    position: relative;
	}

	.btn-circle {
	  width: 100px;
	  height: 100px;
	  text-align: center;
	  padding: 6px 0;
	  font-size: 20px;
	  line-height: 1.428571429;
	  border-radius: 50%;
	  font-weight: bold;
    padding-top: 33px;
    padding-left: 7px;
	}
}

.dashboard-page-container.full-width {
	margin-left: 0.5%;
	width: 99%;
	margin-top: 15px;
}

.dashboard-row-margin {
	margin-left: 15px;
	margin-right: 15px;
}

.delete-obj:hover {text-decoration: none;}


.dashboard-form-wrapper {
	margin-left: 0.5%;
	width: 99%;
}

h1.dashboard-title {
	margin-bottom: 35px;
}

table.lg-table thead tr{
  font-size: 20px;
}
table.lg-table tbody tr td{
  font-size: 16px;
}  

input.edit-btn-large {
   font-size: 17.5px;
   padding: 10px 16px;
   height: inherit;
} 

@media only screen and (max-width: 991px){

	.dashboard-page-container  {
		.well {
			.dashboard-title {	
				font-size: 15px !important;

				span {
					font-size: 15px !important;
				}
			}

			.fa-plus {
		    font-size: 30px;
		    color: #000;
		    cursor: pointer;
		    padding: 7px;
		  }
		}



		.event-counter { 
			width: 100%;
		}
	}
	.dashboard-page-container {
    margin-top: 10px;
	}
}

@media only screen and (max-width: 480px){
	.mobile-left-copy-dashboard {
		width: 82%;
	}
}
