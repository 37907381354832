.preview-wrapper {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.sm2-bar-ui {
  font-size: 12px;
}
.sm2-bar-ui .sm2-main-controls,
.sm2-bar-ui .sm2-playlist-drawer {
  background-color: #3fa4ff;
}
.sm2-bar-ui .sm2-inline-texture {
  background: #000;
}

.talent-profile-wrapper {
	width: 100%;
	float: left;
	font-family: 'helvetica';
	-webkit-transition: width 0.5s;
  transition: width 0.5s;
  background: #000;

  .hide-alert-bg {
    font-size: 30px;
    padding: 5px;
    margin-left: 13px;
  }

	.profile-alert {
    margin-bottom: 0px !important;
    float: left !important;
    width: 100% !important;
    
    i {
	    font-size: 22px;
    }
	}

	.profile-header {
		height: 90px;
    background-color: #000000;
    box-shadow: 1px 1px 1px#000;
    width: 100%;
    position: absolute;
    z-index: 99;

		.profile--logo {
	    position: absolute;
	    top: 0;
	    right: 0;
	    padding: 15px;

			img {
				max-width: 220px;
			}
		}
	}

	.profile-hero {
		float: left;
    position: fixed;
    height: 550px;
    top: 0px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.65;
	}

  .profile-top {
    font-size: 35px;
    float: right;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: -170px;

    .profile-copy {
      display: block;
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;

    }

    .talent---socials {
      width: 100%;
      float: left;
    }

  	.talent---name {
  		font-family: 'brandon-regular';
	    text-transform: uppercase;

  		display: inline;
  	}
  	.spacer {
  		display: inline;
  		height: 48px;
			width: 3px;
			border: 1px solid #fff;
			margin: 5px;
			margin-right: 10px;
  	}
  	.talent---location {
  		font-family: 'minion-italic'; 
  		display: inline;
  	}

  	.social-sm-grp {
  		text-align: center;
  		position: relative;
  		display: inline;
  		margin: 9px;
  		top: -5px;
  	}
  	.social-lg-grp {
  		text-align: center;
  		position: relative;
  		float: right;
  		width: 100%;
  		float: left;
  	}

  	.talent---socials {
	    padding-bottom: 20px;

  		.fa {
  			font-size: 20px;
  			color: #fff;
  			height: 40px;
  			width: 40px;
  			border: 1.5px solid #fff;
  			border-radius: 50%;
  			text-align: center;
  			padding: 8px;
  			cursor: pointer;

  			&:hover {
  				background-color: #fff;
  				color: #000;
  				text-decoration: none !important;

  			}
  		}
  	}
  }

  .profile-content-fold {
    top: 500px;
    margin-bottom: 50px;

    .profile-content-wrapper {
      /*padding-top: 25px;*/

      .profile-copy {
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        color: #9b9b9b;

        .btn {
          cursor: default;
          margin-bottom: 8px;
          
          &:hover {
            background-color: #FFF;
          }
        }
      }

      .video-container {
        margin-top: 40px;
        margin-bottom: 40px;
        float: left;
        width: 100%;
        position: relative;

        iframe {
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  .profile--picture {
    position: relative;
    margin-top: -265px;

    img {
      border-radius: 50%;
      width: 100%;
      border: 2px solid #fff;
    }
  }

  .profile-title {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #4a4a4a;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .gallery-image {
    background: #000;

    .fa-flag {
      position: absolute;
      color: red;
      background-color: rgba(0,0,0,0.1);
      padding: 10px;
      border-radius: 50%;
      margin: 10px;
      z-index: 100;
    }

    &:hover {
      .fa {
        background-color: rgba(0,0,0,0.4);
      }
    }

    img {
      border-bottom: 1px solid #fff;
    }
    
    img:hover {
      opacity: 0.75;
    }
  }

  .profile-footer {
    position: relative;
    float: left;
    padding-top: 50px !important;    
    padding-bottom: 30px !important;
    text-align: center;

    .profile--logo {
      float: left;
      margin-right: 10%;
    }

    .element {
      display: inline;
      font-family: 'brandon-regular';
      letter-spacing: 2px;
      opacity: 0.75;
      margin: 2%;

      a {
        font-size: 19px;
        color: #4a4a4a;
        text-transform: uppercase;

        &:hover {
          color: #000000;
          text-decoration: none;
        }
      }

    }      
    
  }
}



@media only screen and (max-width: 991px) {
  .talent-profile-wrapper .profile-footer .element {
    display: block;
    font-family: 'brandon-regular';
    letter-spacing: 2px;
    opacity: 0.75;
    float: left;
    width: 100%;
    margin-top: 30px;
    margin-left: 10px;
  }

	.bootstrap-tagsinput .tag {
		line-height: 40px;
	}

	.mix-row {
		overflow-x: scroll;
	}
	.dashboard-edit-form .edit-mix {
    top: 1px;
    right: 2px;
    margin: 5px;
  }

  .talent-profile-wrapper {

    .profile--logo {
      &:hover {
        opacity: 0.8;
      }
    }

    .profile-alert {
      z-index: 100000;
      position: fixed;
    }
    .profile-top {
      width: 100%;
    }

    .profile--picture {
      position: absolute;
      top: -118px;
      left: 0;
      right: 0;
      text-align: center;

      img {
        border-radius: 50%;
        width: 90%;
        border: 2px solid #fff;
        float: none;
        max-width: 200px;
      }
    }

	  .profile-header .profile--logo {
	    position: absolute;
	    top: 18px;
	    width: 100%;
	    text-align: center;
	    padding: 0;

	    img {
	      max-width: 175;
	    }
	  }
	}
}

@media only screen and (max-width: 600px) {
	.talent-profile-wrapper {
		.profile-top {
			font-size: 25px;

			.profile-copy {
				
			}
      .talent---location {
        float: left;
        width: 100%;
        margin-bottom: 10px;
      }
		}
	}
}
