// Place all the styles related to the welcome controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.ui-widget-content .ui-state-focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  background-image: none;
}

.payments-talent-no-events {
  font-style: italic;
}

.adjustment-checkbox {
  font-size: 16px;
  font-family: sans-serif;
}

.payments-paginator {
  margin-top: 0px;
  margin-bottom: 20px;
}

.payments-validation-progress {
  margin-left: 25px;
  margin-right: 25px;
}

.payments-validation-errors {
  margin-left: 25px;
  margin-right: 25px;
}
