@mixin shadows($x_offset: 0px, $y_offset: 1px, $size: 8px, $color: rgba(0, 0, 0, 0.5)) { 
  -webkit-box-shadow: $x_offset $y_offset $size $color;
  -moz-box-shadow:    $x_offset $y_offset $size $color;
  -ms-box-shadow:     $x_offset $y_offset $size $color;
  -o-box-shadow:      $x_offset $y_offset $size $color;
  box-shadow:         $x_offset $y_offset $size $color;
}

@mixin inset_shadows($x_offset: 0px, $y_offset: 1px, $size: 8px, $color: rgba(0, 0, 0, 0.5)) { 
  -webkit-box-shadow: $x_offset $y_offset $size $color inset;
  -moz-box-shadow:    $x_offset $y_offset $size $color inset;
  -ms-box-shadow:     $x_offset $y_offset $size $color inset;
  -o-box-shadow:      $x_offset $y_offset $size $color inset;
  box-shadow:         $x_offset $y_offset $size $color inset;
}

@mixin rounded_corners($radius: 5px) { -moz-border-radius: $radius; -webkit-border-radius: $radius; border-radius: $radius;}

@mixin gradiant ($color_a:#aaa, $color_b:#bbb) { 
  position:relative;
  background-image:     -webkit-linear-gradient(top, $color_a, $color_b);                       /* Chrome 10+, Saf5.1+ */
  background-image:     -webkit-gradient(linear, 0% 0%, 0% 100%, from($color_a), to($color_b)); /* Saf4+, Chrome */
  background-image:     -moz-linear-gradient(top,$color_a,$color_b);                            /* FF3.6 */
  background-image:     -ms-linear-gradient(top, $color_a, $color_b);                           /* IE10 */
  background-image:     -o-linear-gradient(top, $color_a, $color_b);                            /* Opera 11.10+ */
}

/* -------- resets ---------------*/

.rs_dialog_holder { font-size:14px; color:black;
  a {color:black;}
  input[type=button] {
    font: small/normal Arial,sans-serif;
    background: #F5F5F5; color: #444; border: 1px solid #ccc;
    font-size: 11px; font-weight: bold; height: 27px; line-height: 27px; outline: none; padding: 0 8px; text-align: center;

    @include rounded_corners(2px);
    @include gradiant(#f5f5f5, #f1f1f1);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#f5f5f5',EndColorStr='#f1f1f1');

    &:hover {border-color:#aaa; color:#222; background-color:#f9f9f9; @include shadows(0px, 1px, 2px, rgba(0,0,0,0.2)); }
    &:focus {border-color:#1E90FF;}
    &:active {border-color:#1E90FF;}
  }
}

/*------- defaults ------------ */
.rs_dialog_holder { font-family:helvetica, arial, 'san-serif'; color:#222; font-size:12px;}

/*------- specifics ------------ */

select {
  option.bold {font-weight:bold; color:red;}
}

.rs_dialog_holder { position:fixed; left:0px; right:0px; top:0px; bottom:0px; padding-left:50%; background-color:rgba(255,255,255,0.2); z-index:50;
  .rs_dialog { background-color:#f6f6f6; border:1px solid #acacac; @include shadows(1px, 3px, 8px, rgba(0,0,0,0.25)); @include rounded_corners(7px);
              display:inline-block; min-width:200px; margin-left:-125px; overflow:hidden; position:relative;
    .rs_dialog_content { padding:10px;
      h1 { font-size:16px; padding:0px; margin:0 0 10px 0;
        a {float:right; display:inline-block; height:16px; width:16px; background-image:image-url("../images/recurring_select/cancel.png"); background-position:center; background-repeat:no-repeat;}
      }

      p { padding:5px 0; margin:0; line-height: 14px;
        label {margin-right:10px;}
      }

      a { -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }

      .freq_option_section { display:none;
        label { font-weight: bold; }
        .rs_interval {width:30px; text-align:center; display: inline-block;}

        .day_holder { border-left:1px solid #ccc; position:relative; margin-top:5px; height:26px;
          a {display:block; padding:5px 7px; font-size:14px; border-style:solid; border-color:#ccc; border-width:1px 1px 1px 0px; float:left; text-decoration:none; font-weight:bold; @include inset_shadows(0px, 1px, 2px, rgba(0,0,0,0.1)); background-color:#fff;;
            &.selected {background-color:#89a; color:#fff; @include inset_shadows(1px, 1px, 2px, rgba(0,0,0,0.2));  @include gradiant(#9ab, #789); }
            &:hover { cursor:pointer; background-color:#dde;}
          }
        }

        .rs_calendar_day, .rs_calendar_week {
          width:155px;
          a {display:inline-block; text-align:center; width:15px; padding:5px 3px; font-size:12px; border-style:solid; border-color:#ccc; border-width:1px 1px 1px 1px; margin:-1px 0 0 -1px; line-height:10px; background-color:#fff; font-weight:bold;
            &.selected {background-color:#89a; color:#fff; @include gradiant(#9ab, #789); @include inset_shadows(0px, 1px, 2px, rgba(0,0,0,0.2)); text-shadow:0 1px 1px #333;}
            &:hover { cursor:pointer; background-color:#dde;}
          }
          a.end_of_month { width: 81px; }
        }
        .rs_calendar_week {
          width: 191px;
          span {
            display: inline-block;
            width: 35px;
          }
        }

        .monthly_rule_type {
          span {
            margin-right: 15px;
          }
        }
      }


      .rs_summary { padding:0px; margin-top:15px; border-top:1px solid #ccc;
        span {font-weight:bold; border-top:1px solid #fff; display:block; padding:10px 0 5px 0;}
        &.fetching {color:#999;
          span {background-image:image-url("../images/recurring_select/throbber_13x13.gif"); background-position:center; background-repeat:no-repeat; display:inline-block; height:13px; width:13px; margin-top:-4px; padding-right:5px;}
        }
        label {font-weight:normal;}
      }

      .controls { padding:10px 0px 5px 0px; min-width:170px; text-align:center;
        input[type=button] { margin:0px 5px; width:70px;
          &.rs_save {color:#333; }
          &.rs_cancel {color:#666;}
          &.disabled {color:#aaa; }
        }
      }
    }

    &.animated {
      .controls {position:absolute; bottom:10px; left:10px;}
      .rs_summary, .freq_option_section {display:none;}
    }

  }
}