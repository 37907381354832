// Place all the styles related to the Calendars controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

#calendar_spin {
  padding-top: 80px;
  padding-bottom: 50px;
}

.howto-video{
  border:1px solid gray;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

video {
  max-width: 100%;

  /* just in case, to force correct aspet ratio */
  height: auto !important;
}

.talent-home-instructions {
  font-size: 125%;
  font-weight: 500;
}


@media only screen and (max-width: 700px) {

   #events-show-wrapper {

    float: left;
    margin-bottom: 400px;

    .view-types {
      float: left;
      width: 100%;
      position: relative;

      #view-type-buttons {
        margin: 10px 0 10px 0;
      }
    }
  }

  .calendar-media-query-wrapper {
    
    #events-index-wrapper {
      position: absolute;
      top: 164px;
      z-index: 100;
    }

    /*position: absolute;
    top: 0px;

    #view-type-buttons {
      margin: 0 0 0 !important;
      left: 0;
      position: relative;
      top: -287px;
      width: 100% !important;
    }


    #events-show-container {
      top: 125px;
    }*/

    .table > tbody > tr {
      width: 100%;
      float: left;
    }

    #events-index-wrapper .simple-calendar .day {
      padding: 0;
      font-size: 10px;
      padding: 10px !important;
    }

    .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
      width: 40px;
    }

    #events-show-wrapper .day-event .event-copy {
      font-size: 15px;
      margin-top: 5px;
    }
  }
}

.hero-container.visitor {
  margin: 0px;
}

@media only screen and (min-width: 991px){
  .mobile-hide {
    display: block;
  }
  
  .mobile-table-hide {
    display: table-cell;
  }

  .desktop-hide {
    display: none;
  }

  #events-show-wrapper {
    position: relative;
    float: left;
    overflow-y: scroll;
    height: 100%;
  }
  #yield-container {
    overflow-y: scroll;
    height: 100%;    
  }

  #sidebar-container {
    width: 15%;
  }

  .hero-container.visitor {
    padding-top: 10%;
  }  

  .login-spacer {
    margin-top: 65px;
  }

}

@media only screen and (max-width: 991px){
  #yield-container.member {
    top: 60px;
    position: relative;
  }

  #yield-container.visitor {
    height: 100%;
    top: 0px;
  }

  #events-index-wrapper {
    height: auto !important;
  }

  .hero-container.visitor {
    padding-top: 15%;
  }

  .login-spacer {
    margin-top: 20px;  
  }
}
