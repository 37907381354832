.panel-primary a.collapse-link {
  color: white; 
}

.panel-success a.collapse-link {
  color: #468847; 
}

.campaign-event-user > td {
  vertical-align: middle !important;
}

.campaign-event-user.booked{
  font-style: italic;
  background: #dff0d8;
  border-color: #d6e9c6;
}

.campaign-navigation {
	position: relative;
	float: left;
	width: 100%;
	height: 65px;

}

.campaign_row, .event-request-row {
  &:hover {
    background-color:#eee;
    cursor:pointer;
  }
}

.search_talent_table a {
	text-decoration: underline;
}

.search_talent_table tr.search_talent_row_invited{
	background: hsl(150, 50%, 50%);
}

.search_talent_row_invited {
	color: white;
}

.search_talent_row_invited a{
	color: white;
}

.campaigns_wait_page {
	float: right;
}

.campaigns_region_selector {
	min-width: 120px;
}

.campaign_confirmed_at {
	color: green;
	font-weight: bold;
}

.campaign-header-email {
	font-size: medium;
}

.campaign_message {
	font-size: medium;
	line-height: 140%;
}

.campaign_message_ach {
	font-style: italic;
	color: #CCCC00;
}

.campaign_event_row {

	.td_btn_row {
		padding: 5px;
	}
	.event_confirm_btn {
		padding: 6px 8px;
		font-size: 12px;
		border-radius: 5px;
		width: 100%;
	}
}

.modal-talent-row-selected {
  background: #428bca;
  color: white;
}

.modal-talent-row-selected a{
  color: white;
}

.modal-talent-row-selected:hover a{
  color: white;
}

.modal-talent-row-conflict {
  font-style: italic;
  color: #d3d3d3;
}

.modal-talent-row-added {
  font-style: italic;
  font-weight: bold;
  cursor: not-allowed;
}


#multi-event-select {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  [id="event-0"]:checked ~ .events-to-book [for="event-0"],
  [id="event-1"]:checked ~ .events-to-book [for="event-1"],
  [id="event-2"]:checked ~ .events-to-book [for="event-2"], 
  [id="event-3"]:checked ~ .events-to-book [for="event-3"],
  [id="event-4"]:checked ~ .events-to-book [for="event-4"],
  [id="event-5"]:checked ~ .events-to-book [for="event-5"],
  [id="event-6"]:checked ~ .events-to-book [for="event-6"],
  [id="event-7"]:checked ~ .events-to-book [for="event-7"],
  [id="event-8"]:checked ~ .events-to-book [for="event-8"],
  [id="event-9"]:checked ~ .events-to-book [for="event-9"],
  [id="event-10"]:checked ~ .events-to-book [for="event-10"],
  [id="event-11"]:checked ~ .events-to-book [for="event-11"],
  [id="event-12"]:checked ~ .events-to-book [for="event-12"],
  [id="event-13"]:checked ~ .events-to-book [for="event-13"],
  [id="event-14"]:checked ~ .events-to-book [for="event-14"],
  [id="event-15"]:checked ~ .events-to-book [for="event-15"],
  [id="event-16"]:checked ~ .events-to-book [for="event-16"],
  [id="event-17"]:checked ~ .events-to-book [for="event-17"],
  [id="event-18"]:checked ~ .events-to-book [for="event-18"],
  [id="event-19"]:checked ~ .events-to-book [for="event-19"],
  [id="event-20"]:checked ~ .events-to-book [for="event-20"],
  [id="event-21"]:checked ~ .events-to-book [for="event-21"],
  [id="event-22"]:checked ~ .events-to-book [for="event-22"],
  [id="event-22"]:checked ~ .events-to-book [for="event-22"],
  [id="event-23"]:checked ~ .events-to-book [for="event-23"],
  [id="event-24"]:checked ~ .events-to-book [for="event-24"],
  [id="event-25"]:checked ~ .events-to-book [for="event-25"],
  [id="event-26"]:checked ~ .events-to-book [for="event-26"],
  [id="event-27"]:checked ~ .events-to-book [for="event-27"],
  [id="event-28"]:checked ~ .events-to-book [for="event-28"],
  [id="event-29"]:checked ~ .events-to-book [for="event-29"],
  [id="event-30"]:checked ~ .events-to-book [for="event-30"],
  [id="event-31"]:checked ~ .events-to-book [for="event-31"],
  [id="event-32"]:checked ~ .events-to-book [for="event-32"],
  [id="event-33"]:checked ~ .events-to-book [for="event-33"],
  [id="event-34"]:checked ~ .events-to-book [for="event-34"],
  [id="event-35"]:checked ~ .events-to-book [for="event-35"]{
    display: inline-block;
  }
  [id="event-0"]:checked ~ .boxes [for="event-0"],
  [id="event-1"]:checked ~ .boxes [for="event-1"],
  [id="event-2"]:checked ~ .boxes [for="event-2"],
  [id="event-3"]:checked ~ .boxes [for="event-3"],
  [id="event-4"]:checked ~ .boxes [for="event-4"],
  [id="event-5"]:checked ~ .boxes [for="event-5"],
  [id="event-6"]:checked ~ .boxes [for="event-6"],
  [id="event-7"]:checked ~ .boxes [for="event-7"],
  [id="event-8"]:checked ~ .boxes [for="event-8"],
  [id="event-9"]:checked ~ .boxes [for="event-9"],
  [id="event-10"]:checked ~ .boxes [for="event-10"],
  [id="event-11"]:checked ~ .boxes [for="event-11"],
  [id="event-12"]:checked ~ .boxes [for="event-12"],
  [id="event-13"]:checked ~ .boxes [for="event-13"],
  [id="event-14"]:checked ~ .boxes [for="event-14"],
  [id="event-15"]:checked ~ .boxes [for="event-15"],
  [id="event-16"]:checked ~ .boxes [for="event-16"],
  [id="event-17"]:checked ~ .boxes [for="event-17"],
  [id="event-18"]:checked ~ .boxes [for="event-18"],
  [id="event-19"]:checked ~ .boxes [for="event-19"],
  [id="event-20"]:checked ~ .boxes [for="event-20"],
  [id="event-21"]:checked ~ .boxes [for="event-21"],
  [id="event-22"]:checked ~ .boxes [for="event-22"],
  [id="event-23"]:checked ~ .boxes [for="event-23"],
  [id="event-24"]:checked ~ .boxes [for="event-24"],
  [id="event-25"]:checked ~ .boxes [for="event-25"],
  [id="event-26"]:checked ~ .boxes [for="event-26"],
  [id="event-27"]:checked ~ .boxes [for="event-27"],
  [id="event-28"]:checked ~ .boxes [for="event-28"],
  [id="event-29"]:checked ~ .boxes [for="event-29"],
  [id="event-30"]:checked ~ .boxes [for="event-30"],
  [id="event-31"]:checked ~ .boxes [for="event-31"],
  [id="event-32"]:checked ~ .boxes [for="event-32"],
  [id="event-33"]:checked ~ .boxes [for="event-33"],
  [id="event-34"]:checked ~ .boxes [for="event-34"],
  [id="event-35"]:checked ~ .boxes [for="event-35"]{
    background-position: .75em;
    padding-left: 3.75em;
    background-color: #369;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
  }

  .campaign-event-wrapper {
  	position: relative;
  	float: left;
  	width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  
  .boxes {
  	padding: 1px;
    float: left;
    width: 100%;
    border: 1px solid gray;
    margin: 2.5px;
  }

  .box-header {
    font-weight: bold;
    font-size: 20px;
    .event-info {
      border-right: 3px solid #dedede;
    }
  }

  .event-info {
  	padding-left: 10px;
  	padding: 5px;
    text-align: center;
  }

  /* event boxes */
  .boxes label {
    display: block;
    float: left;
    width: 100%;
    background: #dedede;
    font-weight: normal;
    background-size: 2em;
    color: #000;
    cursor: pointer;
    font-size: 13px;
    transition: all .3s;
    margin: 0;

    top: -9px;
    left: 36px;

    &:hover{
    	opacity: 0.8;
    }
  }

  .events-to-book {
  	float: left;
    margin-top: 10px;
  }

  
  /* tags */
  .events-to-book label {
    display: none;
    margin: 5px;
    background: #666;
    padding: .25em .5em .25em .5em;
    color: #fff;
    cursor: pointer;
    border-radius: .25em;
  }

  .events-to-book label:before {
    content: 'x';
    margin-right: .5em
  }

  #book-events-button {
    background-color: #369; 
    color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    &:hover{
      opacity:  0.9;
    }
  }

  .custom-checkbox {
    pointer-events: none;
    /* Base for label styling */
    /* Base for label styling */
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0; 
      top: 2px;
      width: 30px;
      height: 30px;
      border: 1px solid #aaa;
      background: #f8f8f8;
      border-radius: 3px;
    }
    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: '✔';
      position: absolute;
      top: 8px;
      left: 5px;
      font-size: 26px;
      line-height: 0.8;
      color: #369;
      transition: all .2s;
    }
    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
    /* disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
    [type="checkbox"]:disabled:checked + label:after {
      color: #999;
    }
    [type="checkbox"]:disabled + label {
      color: #aaa;
    }
    /* accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
      border: 1px dotted blue;
    }

    /* hover style just for information */
    label:hover:before {
      border: 1px solid #4778d9!important;
    }
  }
}

.campaign_row > td, .campaign_event_row > td {
  font-size: 15px !important;
}


.campaign-jumbotron {
  padding-top: 5% !important; 
  line-height: inherit !important;
  margin-bottom: 0px !important;
  /* border:1px solid #4a4a4a; */
}

.campaign-hr {
  color: #4a4a4a;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1400px){
  #multi-event-select .boxes label {
    top: -9px;
    left: 24px;
  }
}

@media only screen and (max-width: 991px){
  #multi-event-select .boxes label {
    top: -57px;
    left: 11px;
  }
}

@media only screen and (max-width: 1024px){
  .campaign-message {
    margin-left: 25px;
    width: 90%;
  }
}
