.tt-input, 
.tt-hint {
    width: 396px;
    height: 30px;
    padding: 8px 12px;
    font-size: 18px;
    line-height: 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
}

.tt-input { 
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    vertical-align: middle !important;
}

.tt-hint {
    color: #999;
}

.tt-menu { 
    width: 422px;
    margin-top: 12px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
}

.typeahead-container .tt-input, .typeahead-container .tt-hint {
    border: none;
    box-shadow: none;
}

.typeahead-form-button {
    height: 44px;
    padding-top: 12px;
}

.form-control.typeahead-container {
    height: unset;
    z-index: unset;
}

.tt-suggestion.tt-cursor {
    color: #ffffff;
    background-color: #337ab7;
}

.typeahead-container.search .tt-input,
.typeahead-container.search .tt-hint, {
    width: unset;
    padding: 0px;
    font-size: 20px;
}

.typeahead-container.search .tt-menu {
    width: unset;
}
