$pagination-button-color: #4a4a4a;

.digg_pagination {
  background: white;
  cursor: default;
  /* self-clearing method: */ 
}

.digg_pagination, .pagination {
  margin-top: 0px;
  margin-bottom: 0px;  
}

.digg_pagination a, .digg_pagination span, .digg_pagination em {
  padding: 0.2em 0.5em;
  display: block;
  float: left;
  margin-right: 1px; 
}

.digg_pagination .disabled {
  color: #999999;
  border: 1px solid #dddddd; 
}

.digg_pagination .current {
  font-style: normal;
  font-weight: bold;
  background: $pagination-button-color;
  color: white;
  border: 1px solid $pagination-button-color; 
}

.digg_pagination a {
  text-decoration: none;
  color: #4a4a4a;
  border: 1px solid $pagination-button-color; 
}
  
.digg_pagination a:hover, .digg_pagination a:focus {
  color: #fff;
  background-color: $pagination-button-color;
}

.digg_pagination .page_info {
  background: #2e6ab1;
  color: white;
  padding: 0.4em 0.6em;
  width: 22em;
  margin-bottom: 0.3em;
  text-align: center; 
}
    
.digg_pagination .page_info b {
  color: #000033;
  background: #6aa6ed;
  padding: 0.1em 0.25em;
}

.digg_pagination:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; 
}

* html .digg_pagination {
  height: 1%; 
}

*:first-child + html .digg_pagination {
  overflow: hidden; 
}