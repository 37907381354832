.line-item-total {
}

.row-amount-mismatch {
  color: orange;
}

.table > tbody > tr.vertical-middle > td {
  vertical-align: middle;
}

.invoice-preferences {
  input {
    position: relative;
    top: 3px;
  }
  .input-group {
    margin-bottom: 0;
  }
}

.input-group-addon-fixed-width {
  min-width: 107px;
}