.pending {
}

.responded {
	td {
		color: #00CC66;
	}
}

.failed {
	td {
		color: #EB0404;
	}
}

.detailed-message-container {
	margin-top: 10px;
	padding-top: 5px;
	border-top: 1px solid #E8E8E8;
}

ul.token-input-list-facebook {
	width: 100%;
	border: 1px solid #cccccc;
	border-radius: 4px;
	margin-bottom: 20px;
}
.send-button{
	width: 80%;
	margin: 0 auto;
	position: relative;
	text-align: center;
	margin-top: 35px;
}

div.token-input-dropdown-facebook{
	background-color: #D5CBD0 !important;
	color: #ccc !important;
	opacity: 0.9;
}

.token-input-dropdown-facebook span {
	height: 50px !important;
	color: red !important;
}

.token-input-dropdown-facebook li{
	height: 40px;
	font-size: 12px;
	line-height: 30px !important;
	vertical-align: middle;
}

.messages-table-container {
	margin: 0 10px 0 10px;
}

.messages-table {
	//box-sizing:border-box;
	//-moz-box-sizing:border-box;
	//-webkit-box-sizing:border-box;
}

#scheduled-form {
	margin-bottom: 0px;
}