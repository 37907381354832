.zip-code {
  margin-top: 15px;
  background-color: #FDFDFD;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 300;
  color: #676767;
  font-size: 15px;
}

.save-for-later {
  font-size: 15px;
}

.card-type {
  height: 23px;
  width: 32px;
  background: url("https://cardjs.co.uk/img/cards.png") 0 23px no-repeat;  
}

.card-type-visa {
  background-position: 0 0;
}

.card-type-mastercard {
  background-position: -32 0;
}

.card-type-american-express {
  background-position: -64 0;
}

.card-type-discover {
  background-position: -96 0;
}

.card-type-diners {
  background-position: -128 0;
}

