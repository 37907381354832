
.invite-list {

	.invitee {
		margin: 5px;
	}

	.btn-sm {
		
		margin: 5px;
		float: left;
		padding: 6px;
		width: 120px;
	}
}