.fa.fa-large {
  font-size: 24px;
}

a > .fa.make-default {
  color: black;
  text-decoration: none;
}

@font-face {
  font-family: 'bebas';
  src: url('../fonts/BebasNeue-webfont.eot');
  src: url('../fonts/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/BebasNeue-webfont.woff') format('woff'),
       url('../fonts/BebasNeue-webfont.ttf') format('truetype'),
       url('../fonts/BebasNeue-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'brandon-regular';
  src: url('../fonts/brandon_reg-webfont.eot');
  src: url('../fonts/brandon_reg-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/brandon_reg-webfont.woff') format('woff'),
       url('../fonts/brandon_reg-webfont.ttf') format('truetype'),
       url('../fonts/brandon_reg-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'minion';
  src: url('../fonts/MinionPro-Semibold.eot');
  src: url('../fonts/MinionPro-Semibold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MinionPro-Semibold.woff') format('woff'),
       url('../fonts/MinionPro-Semibold.ttf') format('truetype'),
       url('../fonts/MinionPro-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'minion-italic';
  src: url('../fonts/MinionPro-SemiboldIt.eot');
  src: url('../fonts/MinionPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MinionPro-SemiboldIt.woff') format('woff'),
       url('../fonts/MinionPro-SemiboldIt.ttf') format('truetype'),
       url('../fonts/MinionPro-SemiboldIt.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}