.btn-google-signin {    
  border: none;
  width: 191px;
  height: 46px;
  background-size: 191px;
}
.btn-google-signin:hover, .btn-google-signin:focus {
  background-size: 191px;
}  

.btn-google-signin:active {
  background-size: 191px;
}   

.btn-google-signin:disabled {
  background-size: 191px;
}    