
#event-list-index-wrapper {
  margin-top: 25px;

  .view-types {
    font-size: 20px;
    position: relative;
    text-align: left;
    float: left;
    width: 100%;
    padding: 10px;

    margin-top: 25px;
    padding-right: 25px;

    .fa {
      color: #000;
      padding: 6px;
    }

    .active {
      text-shadow: -2px 0px 10px #fff; 
    }
  }

  #events-list-index-table {
    font-size: 13px;
  }
}

.event {
  height: auto;
  padding-top: 10px;
  background: rgba(0, 0, 0, 0.05);
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 5px !important;
  padding-bottom: 10px;

  &:hover {
    background: rgba(0, 0, 0, 0.075);
  }
}

.tentative-event {
  border-style: solid;
  border-color: #faebcc !important;
  border-width: thin;
}

.event.within-modal {
  float: none;
}

a.no-link-style {
  text-decoration: none;
  color: black;
}

#events-index-wrapper {
  height: 100%;
  padding-top: 1%;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;


  .view-types {
    font-size: 20px;
    position: relative;
    float: left;
    width: 100%;
    padding: 10px;

    .fa {
      color: #fff;
      padding: 6px;
    }

    .active {
       text-shadow: -2px 0px 10px #000; 
    }
  }


  .calendar-title {

    .pull-left, .pull-right {
      font-size: 22px;
      color: #ffffff;
    }
  }

  .simple-calendar {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    

    .calendar-heading {
      font-size: 30px;

      a:hover, a:active {
        opacity: 0.75;
        text-decoration: none;
      }
      .fa  {
        color: #fff;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .calendar-table {
      padding-top: 17%;
    }

    th {
      text-transform: uppercase;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
      font-weight: 300;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
    }

    td {
      padding-top: 9px;
      font-size: 20px;
      text-align: center;
      color: #fff;

      &:hover {

      }
    }

    .day {
      padding: 13px !important;
    }

    .has-events, .has-events a {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      color: #000;
      cursor: pointer;
    }

    .has-events:hover, .has-events a:hover {
      text-decoration: none;
      opacity: 0.5;
    }

    .today {
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px;
      color: #fff;

      a { 
        color: #fff;
      }
    }

    .active-date, .has-events:hover {
      background: rgba(0,0,0,0.5);
      border-radius: 10px;
      color: #fff;

      a { 
        color: #fff;
      }
    }
  }
}

@mixin title {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  line-height: 40px;
  margin-bottom: 20px;
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin item {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;

  strong {
    font-size: 17px;
    margin-right: 5px;
  }
    
  i {
    font-size: 15px;
  }

  span {
    font-weight: bold;
    margin-right: 6px;
  }  
}

/* show page view */
#event-show-wrapper {
  .event-item {
    @include item;
  }

  .talent-block {
    margin-bottom: 10px;
    float: left;
    position: relative;
    /*min-height: 200px;*/
  }


  .event-title {
    @include title;
  }

  .justified-tab-dropdown-menu {
    width: 100%;
    text-align: center;
  }

  .event-item {

  }

}

.nav-tab-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;    
}

.section-title {
  @include title;
}

.section-title.panel-section-title {
  margin-bottom: 0px;
  border-bottom: none;
}

.section-item {
  @include item;
}

.full-width-margin-wrapper {
  margin-left: 2%; 
  width: 96%;
}

#venue-map-wrapper {
  /*float: left;*/
  iframe {
    width: 100%;
    margin-bottom: 30px;
  }
}


/* calendar view */
#events-show-wrapper {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 300;

  .day-agenda {
    font-size: 25px;
    color: #4a4a4a;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .day-event {
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 17px;
    background-color: whitesmoke;
    position: relative;
    float: left;
    border: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    padding: 20px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;


    .event-copy-wrapper {
      position: relative;
      float: left;
      width: 100%;
    }

    .event-title-header {
      font-size: 17px;      
      color: #000;
      float: left;
      font-weight: 900;
      margin-right: 10px;
    }

    .event-copy {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      text-decoration: none !important;
    }
  }

  .wedding {
    background-color: #1c9ed9;
  }
}



/*Client Facing Page Re-Design ^ */


#events .calendar {
  border-collapse: collapse;
  width: 100%;

  td, th {
    font-family: "Lucida Grande", arial, helvetica, sans-serif;
    font-size: 12px;
    padding: 6px;
    border: 1px solid #999;
  }

  th {
    background: #DDD;
    color: #666;
    text-align: center;
    width: 14.2857142857143%;
  }

  td {
    background: #FFF;
    color: #777;
    height: 80px;
    font-size: 16px;
  }

  .notmonth, .notmonth a { color: #CCC; }
  .today { background-color: #D7F2FF; }
}

#events #month {
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
  font-size: 22px;
  a {
    text-decoration: none;
    padding: 0 10px;
    color: #999;
  }
}

$white: #fff !default;
$greydark: #333;
$bluelight: #1BB2E0;
$bluelighter: #9ED3FF;
$bluedark: #2F1BE0;
$greenlight: #1BE06D;
$greendark: #13AD53;
$greenlighter: #9EFFD2;

#events ul {
  font-size: 11px;
  list-style: none;

  .blackout {
    color: $white;
    background-color: $greydark;
  }  

  .booked {
    color: $white;
    background-color: $bluedark;

    &.corporate {
      background-color: $greendark; 
    }    
  }

  .recommended {
    color: $white;
    background-color: $bluelight;  

    &.corporate {
      background-color: $greenlight; 
    }    
  }

  .tentative {
    color: $greydark;
    background-color: $bluelighter; 

    &.corporate {
      background-color: $greenlighter; 
    }
  }
}

.update_time {
  font-weight: bold;
}

.event_row {
  
  &:hover {
    background-color:#eee;
    cursor:pointer;
  }
}

.event_row_tentative {
  color: orange;
}

.event_row_cancelled {
  text-decoration: line-through;
  font-style: italic;
}

.wedding_event_row {
  color: $bluedark;
}

.corporate_event_row {
  color: $greendark;
}

.event-type-selector {
  min-width: 80px;
}

.event-no-talent-pay {
  color: red;
}

.event-talent-pay-sent {
  color: blue;
}

.active-title {
  font-weight: bold;
}


.well.well-empty {
  background-color: transparent;
  border: none;
}

.modal-venue-row-selected, .row-selected {
  background: #428bca;
  color: white;
}

.events-user-row-confirmed {
  font-weight: bold;
}

.events-user-row-cancelled {
  font-style: italic;
  color: #d3d3d3;
}

tr.events-user-row-highlighted {
  color: black;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #eee 10px,
    #eee 20px
  ),
  linear-gradient(
    to bottom,
    #fff,
    #ddd
  )
}

.event-details-block {
  margin-top: 15px;
}

.event-details-side-margin {
  margin-left: 16px;
  margin-right: 16px;
}

.btn-date-range {
  width: 200px; 
  outline: none !important;  
  color: white;
  background-color: #1c9ed9;
  border-color: #1c93d9;
}  

.btn-date-range:hover, .btn-date-range:focus, .btn-date-range:active, .btn-date-range.active {
  color: white;
  background-color: #1c93d9;
  border-color: #1c93d9;
} 

.table.table-no-margin-bottom {
  margin-bottom: 0px;
}


@media only screen and (max-width: 991px){

  .event-title {
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none !important;
  } 
}

@media only screen and (max-width: 700px){

  #view-type-buttons {
    margin: 30px 0 30px 0;
    width: 100%;
  }

  #events-index-wrapper .calendar-title .pull-left,  .calendar-title {
    font-size: 19px;
  }
  #events-index-wrapper .simple-calendar .calendar-heading {
      font-size: 24px;
  }

  .event-counter {
    display: none;
  }

  #event-list-index-wrapper, #view-type-buttons .btn {
    width: 100%;
    margin-bottom: 3px;
  }

  .input-group-btn {
    line-height: 30px;
  }


  .day-event {
    .btn-blue {
      position: relative;
      float: left !important;
      margin-top: 10px;
    }
  }

  .event-search-field {
    width: 95% !important;
    margin:  5px;
  }
  .event-date-range-selector {
    width: 95% !important;
    margin:  5px;

  }
}

.small-link-font {
  font-style: italic;
  font-size: 12px;
}

.assign-company-tabs {
  margin-bottom: 20px;
}
 
 
