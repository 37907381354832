
.metrics-header {
  position: relative;
  float: left;
  width: 100%;
  z-index: 25;
  background-color: rgba(0,0,0,0.95);
  padding-top: 10px;
  padding-bottom: 10px;
}

#task-table {
  td {
    font-size: 12px;
  }
}

.navigation-subtitle {
  text-align: center;
  margin-bottom: 30px;
}

#metrics-container {

  hr.ninety-width {
    width: 90%;
  }

  h6, h4, h5, h3, h2, h1 {
  }

  margin-left: 1%;
  width: 98%;

  h6 {
    font-size: 18px;
  }

  strong {
    font-weight: bold;
    text-shadow: 1px 0px 0px rgba(0, 0, 0, 0.9);
    letter-spacing: 3px;
    margin-left: 5px;
    text-decoration: underline;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 30px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.25);
  }

  a {
    text-decoration: none;

    &:hover{
      text-decoration: none;
      color: rgba(0,0,0,0.85);
    }
  }

  .student-link {
    color: #3071a9 !important;
  }

  .panel-heading {
    h3 {
      strong {
        text-decoration: underline;
      }
    }
  }

  #graph-type-wrapper {
    margin-top: 10px;
  }

  #analytics-date-form .btn {
    min-width: 85px;
  }

  .shared-city-filters {
    margin-bottom: 40px;
    margin-top: 25px;
  }

  .btn.btn-default.filter, .all-filter {
    padding: 4px;
    min-width: 74px;
    margin: 4px;
  }

  .list-group-item {
    font-size: 25px;
  }

  .analytics-main-btn {
    width: 90%;
    margin-bottom: 20px;
  }
  .fa.fa-home {
    color: #000;
    font-size: 20px;
  }

  .active-home {
    color: #fff !important;
  }

  .student { 
    cursor: pointer;
  }
}


#back-all {
  margin-top: 20px;
  margin-bottom: 20px;
}


#analytics-date-form {
  .btn, input {
    width: 30%;
    margin: 1%;
    float: left;
  }
  a {
    display: inline;
  }
  .btn-all {
    width: 96%;
    margin-top: 10px;
  }
}


#analytics-table-style {
  width: 100%;
  div.table-title {
    display: block;
    margin: auto;
    padding:5px;
    width: 100%;
  }

  .hidden-row {
    display: none;
  } 

  .table-title h3 {
    color: #fafafa;
    font-size: 25px;
    font-weight: 400;
    font-style:normal;
    font-family: "Roboto", helvetica, arial, sans-serif;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    text-transform:uppercase;
  }

  /*** Table Styles **/
  .table-fill {
    background: white;
    border-radius:3px;
    border-collapse: collapse;
    height: 175px;
    margin: auto;
    padding:5px;
    /*width: 100%;*/
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;
  }
   
  th {
    color: #D5DDE5;;
    background: #1b1e24;
    border-bottom: 4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-size: 16px;
    font-weight: 100;
    /*padding: 15px;*/
    text-align:l eft;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }

  th:first-child {
    border-top-left-radius:3px;
  }
   
  th:last-child {
    border-top-right-radius:3px;
    border-right:none;
  }
    
  tr {
    border-top: 1px solid #C1C3D1;
    border-bottom-: 1px solid #C1C3D1;
    color:#666B85;
    font-size:12px;
    font-weight:normal;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  }
   
  tr:hover td {
    background:#4E5066;
    color:#FFFFFF;
    border-top: 1px solid #22262e;
    border-bottom: 1px solid #22262e;
  }
   
  tr:first-child {
    border-top:none;
  }

  tr:last-child {
    border-bottom:none;
  }
   
  tr:nth-child(odd) td {
    background:#EBEBEB;
  }
   
  tr:nth-child(odd):hover td {
    background:#4E5066;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius:3px;
  }
   
  tr:last-child td:last-child {
    border-bottom-right-radius:3px;
  }
   
  td {
    background:#FFFFFF;
    /*padding: 15px;*/
    text-align:left;
    vertical-align:middle;
    font-weight:300;
    font-size:12px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #C1C3D1;
  }

  td.bold {
    font-weight: bold;
  }

  td.subtitle {
    font-size: 15px;
  }

  td.red {
    color: red;
  }

  td.green {
    color: #33cc33;
  }

  td.blue {
    color: #0000ff;
  }

  .orange {
    color: #ff9900;
  }

  .with-link {
    &:hover {
      cursor:pointer;
    }
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-left {
    text-align: left;
  }

  th.text-center {
    text-align: center;
  }

  th.text-right {
    text-align: right;
  }

  td.text-left {
    text-align: left;
  }

  td.text-center {
    text-align: center;
  }

  td.text-right {
    text-align: right;
  }

  tr.seperator-row {
    height: 2px;

    td{
      background:#333333;
      padding-bottom: 0px;
    }
  }

}

.orange-cell {
  color: #ff9900;
}

.analytics-input-group {
  width: 90%;
}

.fixed {
  position: fixed;
  top:0; 
  display:block;
}

.margin20 {
  margin: 20px;
}

.green-highlighter {
  background-color: rgba(76, 175, 80, 0.2); 
  position: absolute; 
  width: 96%; 
  height: 100%; 
  top: 0%; 
  left: 2%;
}

.table-highlighted {
  border: 1px solid rgba(76, 175, 80, 1);
}

.edit-hover a{
  visibility:hidden;
}
.edit-hover:hover a {
 visibility:visible;
}

.edit-icon {
  vertical-align: middle;
  margin-top: 12px;  
}

#hidden-menu-bar {
  height: 100%; 
  background-color: #222222; 
  width: 10px; 
  position: fixed; 
  cursor: pointer; 
}

@media only screen and (max-width: 991px) {
  #metrics-container .analytics-main-btn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #analytics-date-form .btn, #analytics-date-form input, #metrics-container .btn.btn-default.filter, .all-filter {
    min-width: 100%;
  }
  #metrics-container .btn.btn-default.filter {
    margin-bottom: 5px;
  }
}