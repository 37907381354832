$black:                 #000 !default;
$grayDarker:            #222 !default;
$grayDark:              #333 !default;
$gray:                  #4a4a4a !default;
$grayLight:             #999 !default;
$grayLighter:           #eee !default;
$white:                 #fff !default;
$smgBlue:               #148CBE !default;

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass";

.center {
  text-align: center;
  h1 {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.pull-right, .pull-left {
  
}

h1 {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.table > tbody > tr {
  height: 35px;
}

.btn-group a:hover {
  text-decoration: none;
}

.table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {   
  padding: 0;   
  padding-left: 10px;   
  padding-right: 5px;   
  padding-top: 10px;    
  font-size: 12px;    
  padding-bottom: 9px;    
}

.tabbed-table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td { 
  padding: 0;   
  padding-left: 10px;   
  padding-right: 5px;   
  padding-top: 10px;    
  font-size: 14px;    
  padding-bottom: 9px;  
}

.table button {
  margin-top: 3px;
}

.btn-default-outline {
  color: #FFF;
  background-color: transparent;
  background-image: none;
  border-color: #ccc;  
}

.btn-default-outline:hover {
  color: #333;
  background-color: #ebebeb;  
  background-color: #adadad;
}

.btn-default-outline.active {
  color: #333;
  background-color: #ebebeb;  
  border-color: #adadad;
}

.btn-primary-outline {
  color: $smgBlue;
  background-color: transparent;
  background-image: none;
  border-color: $smgBlue;
}

.btn-danger-outline {
  background-color: transparent;
  background-image: none;
  border-color: #d43f3a;
  color: #d9534f;

  &:hover {
    background-color: #d9534f;
    color: #fff;
  }
}
.btn-danger-outline.active {
  color: #fff;
  background-color: #d9534f;
}


.btn-primary-outline:hover {
  color: #fff;
  background-color: $smgBlue;  
  background-color: $smgBlue;
}

.btn-primary-outline.active {
  color: #fff;
  background-color: #1678AD;  
  border-color: #105A82;
}

.btn-secondary-outline { 
  color: #4a4a4a;
  background-color: transparent;
  background-image: none;
  border-color: #4a4a4a;
}

.btn-secondary-outline:hover, .btn-secondary-outline.active {
  color: #fff;
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

.btn-warning-outline { 
  color: #f0ad4e;
  background-color: transparent;
  background-image: none;
  border-color: #f0ad4e
}

.btn-warning-outline:hover, .btn-warning-outline.active {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-success-outline {
  color: #5cb85c;
  background-color: transparent;
  background-image: none;
  border-color: #4cae4c;
}

.btn-success-outline:hover, .btn-success-outline.active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-flat {
  border-radius: 0px;
}

.spacer10 {
  margin-top: 10px;
}

.spacer20 {
  margin-top: 20px;
}

.spacer30 {
  margin-top: 30px;
}

.spacer40 {
  margin-top: 40px;
}

.spacer80 {
  margin-top: 80px;
}

.hspacer20 {
  margin-left: 20px;
}

.wait_page {
  margin-left: 20px;
}

.container {
  max-width: 100%;
}

label {
  font-size: 20px;
  
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  line-height: 2;
}

.select-lg > .btn {
  padding: 12px 12px;
}

hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #4a4a4a -moz-use-text-color #FFFFFF;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}


.footer-hr {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.navbar-footer {
  margin-bottom: 0px !important;
}

.footer-right {
  padding-right: 15px;
}

.navbar-nav > li > a {
  font-family: 'bebas';
  letter-spacing: 1px;
  font-size: 16px;
}

body {
  padding-top:70px;
}

.well.background-well {
  border-radius: 0px;
  border: none;
  margin-bottom: 0px;
}

.well.main-card-container {
  border-radius: 0px;
  border: none;
  margin-bottom: 0px;
  padding: 0px; 
  background-color: #e8e8e8;
}

.well.main-card-container.with-padding {
  padding: 10px;
}

.well.main-card-container.public {
  height: 100%;
}

.oh-well-no-well {
  padding: 10px;
}

.breadcrumb-outline {
  border: 1px solid rgba(0,0,0,0.1);
  background-color: transparent;
}

.breadcrumb > .active {
  color: #4a4a4a;
  font-weight: bold;
}

@media (max-width: 990px) {
  .navbar-header {
    background-color: #4a4a4a;
    float: none;
  }
  .navbar-toggle {
    display: block;
    padding: 15px 20px;
  }
  .navbar-collapse {
    max-height: 100%;
    border-top: 1px solid transparent;
    background-color: #4a4a4a;
      /*box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);*/
  }
  .navbar-collapse.collapse {
    display: none!important;
      
  }
  .navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
    padding-left: 20px;

  }
  .navbar-nav>li {
    float: none;   
  } 

  .navbar-nav.navbar-nav-visitor-footer>li {
    float: left !important;
  }  

  .navbar-toggle {
    margin-top: 15px;
  }

  .dashboard-navigation-title {
    margin: 15px !important;
  }  
}

.toggle-group>label {
  margin-top: 0px;
  line-height: 20px;
}

.white-table, .white-well {
  background-color: white;
}

.form-default-readonly {
  .form-control[readonly] {
    background-color: white;
  }  
} 

.breadcrumb.full-width {
  margin-bottom: 10px;
}

.ui-group-buttons .or{position:relative;float:left;width:.3em;height:1.3em;z-index:3;font-size:12px}
.ui-group-buttons .or:before{position:absolute;top:50%;left:50%;content:'or';background-color:#5a5a5a;margin-top:-.1em;margin-left:-.9em;width:1.8em;height:1.8em;line-height:1.55;color:#fff;font-style:normal;font-weight:400;text-align:center;border-radius:500px;-webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.1);box-shadow:0 0 0 1px rgba(0,0,0,0.1);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box}
.ui-group-buttons .or:after{position:absolute;top:0;left:0;content:' ';width:.3em;height:2.84em;background-color:rgba(0,0,0,0);border-top:.6em solid #5a5a5a;border-bottom:.6em solid #5a5a5a}
.ui-group-buttons .or.or-lg{height:1.3em;font-size:16px}
.ui-group-buttons .or.or-lg:after{height:2.85em}
.ui-group-buttons .or.or-sm{height:1em}
.ui-group-buttons .or.or-sm:after{height:2.5em}
.ui-group-buttons .or.or-xs{height:.25em}
.ui-group-buttons .or.or-xs:after{height:1.84em;z-index:-1000}
.ui-group-buttons{display:inline-block;vertical-align:middle}
.ui-group-buttons:after{content:".";display:block;height:0;clear:both;visibility:hidden}
.ui-group-buttons .btn{float:left;border-radius:0}
.ui-group-buttons .btn:first-child{margin-left:0;border-top-left-radius:.25em;border-bottom-left-radius:.25em;padding-right:15px}
.ui-group-buttons .btn:last-child{border-top-right-radius:.25em;border-bottom-right-radius:.25em;padding-left:15px}


@media (min-width: 767px) {
  .table-responsive {
    overflow: inherit;
    width: 100%;
  } 
}

@media (max-width: 450px) {
  .group-filters, .event-filters, .more-filter {
    width: 100%;

    span {
      margin-left: 0px;
    }
  }

  .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    font-size: 12px;
  }

  .event-list-types .btn {
    margin: 5px;
    float: right;
    width: 100%;
  }
}

@media (max-width: 990px) {
  .well.main-card-container {
    min-height: calc(100vh - 80px - 69px)
  }  
}

.navbar {
  margin-bottom: 0px !important;
  border-radius: 0px !important;
}

@media (min-width: 990px) {
  .navbar-nav-visitor-header {
    color: #000;
    background-color: #fff;
  }

  .navbar-nav-visitor-header > li > a{
    padding: 30px;
    font-size: 20px;
  }

  .navbar-nav-visitor-header > li > a:hover {
    color: #000 !important;
  }  

  .well.main-card-container {
    min-height: calc(100vh - 80px)
  }
}