.expandable-form {
  margin-bottom: 0px;
  margin-top: 10px;
}

.expandable-form .input-group-addon {
  cursor: pointer;
}

.filter-icon-margin {
  margin-right: 10px;
}

.selectable-action-form {
  margin-bottom: 0px;
}

.form-group-no-margin {
  margin: 0;
}