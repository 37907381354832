.thumbnail {
    //width: 80%;
    //height: 80%;
}

.gray-hr {
	color: #eeeeee;
}

.light-gray-hr {
    color: #f5f5f5;
}

.ratio{
    position:relative;
    width: 100%;
    height: 0;
    padding-bottom: 50% ; /* % of width, defines aspect ratio*/
    
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;  
}