.row-contact-archived {
  font-style: italic;
  text-decoration: line-through;
}

.company-contact-menu li > a > .glyphicon {
  margin-right: 4px;
}

.company-contact-menu li > a > .fa {
  margin-right: 4px;
}