#loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 2000;
  display: none;

  .fa {
    font-size: 60px;
    position: static;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-top: 21%;
  }
}


#portal-menu-collapse {
  position: relative;
  float: left;
  width: 100%;
  padding-bottom: 40px;

  .nav-link-wrapper {
    span {
      font-size: 20px;
      color: #fff;
      text-decoration: none;
    }
  }

  .fa-caret-down {
    font-size: 20px;
    margin-left: 10px;
    color: #fff;
  }

  a {
    color: #fff;
  }

  li.nav-dropdown-header {
    padding: 20px 0px 10px 0px;
    font-size: 16px;
  }
  
}


.sidebar-wrapper, #portal-menu-collapse {
  z-index: 1000;
  position: relative;  
  min-height: 100%;
  position: relative;
  float: left;
  padding-left: 5%;
  background: #4a4a4a;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  
  .brand-title {
    font-size: 30px;
    font-family: 'bebas';
    letter-spacing: 0.2px;
    line-height: 35px;
    color: #1c9ed9;
  }

  p {
    font-size: 18px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }

  .no-padding {
    padding: 0px;
  }

  .fa-caret {
    color: #fff;
  }

  .hover {
    color: #333333;
    background-color: #ebebeb;
    border-color: #adadad;
  }

  .nav-link-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    float: left;
    width: 100%;
    text-shadow: 0px 0px 5px #000;
  }

  .sub-menu{
    float: left;
    position: relative;
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .active-sub-menu{
    -webkit-transform: rotate(180deg); 
    -moz-transform: rotate(180deg);  
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5); /*for IE*/
  }

  .open-menu {
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .underline {
    text-decoration: underline;
    color: #fff;
  }

  .nav-link {
    font-size: 18px;
    position: relative;
    float: left;
    width: 100%;
    padding-left: 10px;
    
    a {
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      position: relative;
      font-size: 15px;
      float: left;
      width: 100%;
      color: #ffffff;
      margin: 2px;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
    }
  }

  .nav-link-independent {
    padding-left: 0px;
  }

  .nav-link-independent-dashboard {
    padding-bottom: 10px;
  }


  a {
    color: white;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }

  a:hover, span:hover {
    opacity: 0.75;
  }

  .sidebar-nav {
    margin-top: 25px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    float: left;

    img {
      width: 90%;
      max-width: 168px;
    }

    .welcome {
      position: relative;
      float: left;
      margin-top: 12px;
      width: 100%;
      color: #ffffff;
      font-size: 25px;
      font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    }
    
    .dropdown-menu {
      margin-top: -50px;
      margin-left: 140px;
    }
  }
}

.name-div {
  width: 100%;
  font-family: 'bebas';
  font-size: 20px;
  color: #1c9ed9;
  float: left;
  position: relative;
}

.middle-nav {
  position: relative;
  float: left;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 18px;
  color: #ffffff;
}

.bottom-nav{
  position: relative;
  float: left;
  width: 100%;
  
  .logout {
    font-size: 26px;
    font-family: 'bebas';
    height: 43px;
  }
        
}
  
#footer-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: #ffffff;
}

.padding-50 {
  padding-top: 0px;
  padding-bottom: 50px;
}

.dashboard-link {
  font-size: 20px  !important; 
  border-bottom: 1px solid transparent !important; 
  float: left  !important; 
  width: auto  !important;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid gray !important; 
  }
}

.dashboard-link > .badge {
  vertical-align: middle;
  line-height: 14px;
  margin-top: -2px;
}