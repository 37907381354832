.well-toggle {
	padding: 10px;

	span {
		font-size: 20px;
		font-weight: 100;
	}
}

.submit-wrapper {
	width: 100%; 
	text-align: center;

	.btn-primary {
		width: 200px;
    margin-top: 35px;
    padding: 15px;
    color: #fff;
    background-color: #0275d8;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    text-decoration: capitalize;
    transition: all .2s ease-in-out;
    opacity: 0.75;

    &:hover {
    	color: #fff !important;
    	opacity: 1;
    }
	}
}


.dashboard-edit-form {

	label {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 17px;
		font-weight: 500;
	}

	i {
		font-size: 15px;
	}


	.muted {
		color: #55595c;
	}

	.multi-select {
		span {
			font-size: 15px;
	    font-weight: auto;
	    margin: 3px;
	    
	    &:hover {
	    	opacity: 0.8 !important;
	    }
		}
	}

	.fa-cogs {
		position: absolute;
		top: 21px;
		right: 26px;
		font-size: 20px;
		opacity: 0.5;
		color: rgb(0, 0, 0);
		background: #fff;
		padding: 10px;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			opacity: 1.0;
		}
	}
	.gi-edit {
		display: none;
	}
	.mix-edit {
	}

	.mix-row {
		padding: 10px !important; 
		height: auto; 
	}
	.edit-mix {
		position: relative;
		top: -8px;
		right: -8px;
		border: 1px solid rgba(0,0,0,0.25);
	}
	.mix-edit {
		max-width: 290px; 
		margin: 1.5%;
	}

	.bg-select {
		-webkit-transition: all 0.25s ease;
		-moz-transition: all 0.25s ease;
		-o-transition: all 0.25s ease;
		transition: all 0.25s ease;
		cursor: pointer;

		&:hover {
			box-shadow: inset 0 0 10px 10px #428bca;
			opacity: 0.9;
		}
	}

	.bg-active {
		box-shadow: inset 0 0 10px 10px #428bca;
		opacity: 0.9;
	}
}

.dashboard-edit-form.with-padding {
	padding: 10px;
	margin-top: 20px;
}

@media only screen and (max-width: 991px) {
	.bootstrap-tagsinput .tag {
		line-height: 40px;
	}
	.mix-row {
		overflow-x: scroll;
	}
	.dashboard-edit-form .edit-mix {
    top: 1px;
    right: 2px;
    margin: 5px;
  }
}