body {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-weight: 300;
}

.breadcrumbs {
	position: relative;
	float: left;
	padding: 10px;
	font-size: 18px;
	font-style: italic;
}

.well {
	float: left;
	padding-left: 1%;
	padding-right: 1%;
  width: 100%;

  .dashboard-title {
		pointer-events: none;
	}
}

.pointer-none {
	pointer-events: none;
}

.prev-admin {
	position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

	.fa {
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    color: #000;
    font-size: 30px;
	}
}

.map_canvas { 
  border: 1px solid #cccccc;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 1%;
}

#map-container {
	
}
.map-div {
	
}

.bg-blue {
	background-color: #1c9ed9;
}

.mobile-hide {
	display: none;
}

.white-bg {
	background-color: #fff;
}

.mobile-table-hide {
	display: none;
}

.desktop-hide {
	display: block;
}
.show {
	display: block !important;
}

.col {
	padding: 0 !important;
}

.col-padding-right {
  padding-right: 15px !important; 
}

.col-padding {
  padding-left: 15px !important; 
  padding-right: 15px !important;  
}

.relative {
	position: relative;
	float: left;
	width: 100%;
}

.rotated { 
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabled {
	cursor: disabled !important;
}

.btn-blue {
	background-color: #1c9ed9;
	color: #fff;
	font-size: 20px;
	letter-spacing: 1px;
}



.label-info {
  background-color: #0275d8 !important;
  opacity: 0.75 !important;

  &:hover {
	  opacity: 1 !important;
  }
}

input[type="file"]{
	padding: 20px;
	font-size: 15px;
}

.plus-minus {
	input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
		background-color: #cccccc;
	  border: none;
	  border-radius: 10px;
	  padding: 10px;
	}
} 

.birthday-wrapper {

	.bootstrap-select {
		width: 32.5% !important;
		float: left !important;
	}
}

.bootstrap-tagsinput {
	padding: 10px !important;
	width: 100% !important;
  letter-spacing: 1px !important;
  margin-bottom: 0px !important;
}

.bootstrap-tagsinput .tag {
  padding: 8px !important;
  margin: 0px !important;
	font-weight: 300 !important;
	line-height: 37px !important;
  font-size: 14px !important;
}

.bootstrap-tagsinput input[type="text"] {
	min-width: 150px !important;
}

.file-submit-btn {
	background: #0275d8 !important;
  padding: 15px !important;
  letter-spacing: 2px !important;
	opacity: 0.75 !important;
	color: #fff !important; 
	width: 150px !important; 
	margin-top: 20px !important; 
	display:none;

	&:hover {
		opacity: 1;
	}
}

.badge {
  &.badge-success {
    background-color: #5cb85c;
  }
  &.badge-warning {
    background-color: #f0ad4e;
  }
}

.field_with_errors input {
  border-color: #a94442;
}

#yield-container.full-width {
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

#visitor-yield-container {
  height: 100%;
}
