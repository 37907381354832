#talent-index-search {
	select {
		background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
		-moz-appearance: none; 
		-webkit-appearance: none; 
		appearance: none;
	}

	#region_ids {
		background: none;
	}
}

#talent-show-wrapper {
	.event {
		min-height: 150px;
	}
}

td .fa-sign-in {
	font-size: 19px;
  cursor: pointer;
	color: #000;
  padding: 5px;

  &:hover {
  	color: #428bca;
  	opacity: 0.75;
  }
}

.talent_pay_summary_row {
	background-color:#eee;
}

.talent-pay-report-modal {
	width: 70% !important;
}

.talent-pay-line-item-ach-exempt {
	font-style: italic;
	color: 	#d3d3d3;
}

.talent-pay-line-item-events-without-pay {
	color: red;
}

.talent-pay-line-item-sent {
	color: blue;
}

.talent-pay-line-item-exported {
	color: green;
}

.talent-pay-line-item-request {
  color: orange;
}

.pay-report-date-selector {
	width: 40% !important;
}

.pay-report-event-type {
	width: 18% !important;
	text-align: left;
}

.pay-report-submit {
	width: 80% !important;
}

.btn-fm-validate {
	margin-right: 25px;
}

.popover{
    max-width: 100%; 
}

.talent-blackout {
  color: grey;
}

.talent-wedding {
  color: blue;
}

.talent-corporate-event {
  color: green;
}

.talent-cruise {
  color: light-blue;
}

.talent-form-well {
  padding-bottom: 0;

  .input-group:last-child {
    margin-bottom: 0;
  }
}

.btn-primary.btn-talent-submit {
  margin-top: 0px;
}

.badge-orange {
  background-color: orange;
}



