// Place all the styles related to the static_pages controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.footer {
  background-color: #fff;
}

#footer-wrapper {
	padding-right: 15px;
	padding-left: 15px;
}

body {
	padding-top: 0px;
}

.alert-container {
  padding-top: 0px;
}

#login-div {
  position: relative;
  width: 100%;
  float: left;
}


.dashboard-page-container {
	margin-top: 25px;
}

#flash_messages_modal {
  position: fixed;
  z-index: 10000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.75);
  display: none;

	.alert {
    margin: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.5px;
  }

	.close {
		float: right;
		font-size: 38px;
		font-weight: bold;
    line-height: 1;
    margin-top: -10px;
		text-shadow: -1px 1px 0 gray;
		opacity: 0.75;
	}
}

hr {
	position: relative;
	float: left;
	width: 100%;
}

.hero-image-container {
  height: calc(100vh - 80px - 50px);
  background-color: rgba(0,0,0,1);
  position: absolute;
  width: 100%;
  top: 80px;

  .hero-image-banner {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 100%;
      opacity: 0.45;
      background-repeat: no-repeat;
      background-position: center center;
    }
} 

.hero-container {
}

.terms-and-privacy {
  color: lightgrey; 
  font-size: 12px;
}



@media only screen and (max-width: 991px){
	
	#flash_messages_modal {
		position: absolute;
    z-index: 100;
    width: 100%;
    text-align: center;
    opacity: 0.95;
    margin-left: 0;
    top: 63px;

    .alert {
	    padding: 15px;
	    margin: 10px;
	  }

		.close {
	    line-height: 100%;
		}
	}

  #flash_messages_inline {
    position: absolute;
    z-index: 100;
    width: 100%;
    text-align: center;
    opacity: 0.95;
    margin-left: 0;
    top: 63px;

    .alert {
      padding: 15px;
    }
  } 

  .hero-image-container {
    top: 0;
    height: calc(100vh - 55px);
  } 
}

.table-container {
	margin: 0 15px 0 15px;
}


.help-name-div {
	float: none;
}

.help-link {
	text-decoration: underline;
}

.help-container {
	margin-top: 25px;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.flex-wrapper {
  display: flex;  
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;  
  width: 100%;
  height: 100%;
}

.flex-wrapper.flex-wrapper-row {
  flex-direction: row;
}

.flex-wrapper > * {    
  flex-grow: 0;
  flex-shrink: 1;
}

.flex-main { 
  flex: 3 0px; 
  overflow-x: hidden;
  overflow-y: auto;
}