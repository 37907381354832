#view-type-buttons {
  margin-bottom: 20px;
}

.panels-index-form {
  margin-top: 10px;
}

.panel-editable {
  cursor: pointer;
}

.panel-editable.panel-danger:hover {
  background-color: #f5f5f5;
  border-color: #d9534f;
}

.panel-editable.panel-danger:hover > .panel-heading {
  border-color: #d9534f;
}

.panel-editable.panel-primary:hover {
  background-color: #f5f5f5;
  border-color: #285e8e;
}

.panel-editable.panel-primary:hover > .panel-heading {
  border-color: #285e8e;
}

.btn-margin-right {
  margin-right: 6px;
}

.btn-margin-left {
  margin-left: 6px;
}

.progress-no-margin {
  margin: 0px;
}

.table-no-margin-bottom {
  margin-bottom: 0px;
}

.panel-onboarding {
  margin-top: 0px;
  margin-bottom: 10px;
}

.panel-expandable {
  margin-bottom: 0px;
}

.panel-heading-padded {
  padding: 25px;
}

.no-padding {
  padding: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.no-margin {
  margin: 0px;
}

.panel-invoice {
  margin-top: 30px;
}

.panel-body-invoice {
  padding: 30 30 100 30;
}

.panel-side-border {
  border-left: 2mm solid #148CBE;
}

.panel.panel-grey {
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
}

.btn-smg-primary {
  background-color: #1c93d9;
  border-color: #1c93d9;
}

.btn-smg-primary:hover, .btn-smg-primary:focus, .btn-smg-primary:active, .btn-smg-primary.active {
  color: white;
  background-color: #1678AD;
  border-color: #105A82;
}

.table.large-table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  font-size: 16px;
}

p.bigger-font {
  font-size: 16px;
}

.notification-icon {
  font-size: 10px; 
  vertical-align: middle;
  margin-bottom: 12px;
}

.orange-icon {
  color: orange;  
}

.large-red-icon {
  color: red;  
  font-size: 14px;
}

.border-none {
  border: none !important;
}

.fifteen-font {
  font-size: 15px !important;
}

.input-group.full-width {
  width: 100% !important;
}
