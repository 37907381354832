@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";

.navbar-fixed-side {
  padding-top: 20px;

  @media (max-width: $grid-float-breakpoint) {
    /*margin-left: -15px;
    margin-right: -15px;*/
  }

  @media (min-width: $grid-float-breakpoint) {
    $navbar-default-link-hover-bg: darken($navbar-default-bg, 3%);
    $navbar-inverse-link-hover-bg: lighten($navbar-inverse-bg, 5%);
    $navbar-inverse-border: lighten($navbar-inverse-bg, 8%);

    position: fixed;
    /*margin: 0 -15px;*/
    height: 100vh;
    width: inherit;
    overflow: auto;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;

    .container, .container-fluid {
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    .navbar-header {
      float: none;
    }

    .navbar-brand {
      height: auto;
    }

    > .container, > .container-fluid {
      .navbar-brand {
        margin-left: 0;
      }
    }

    .navbar-collapse {
      width: 100%;
      border-top: 1px solid $navbar-default-border;
    }

    .navbar-nav {
      float: none;
      /*margin: 0 (-$navbar-padding-horizontal);*/

      > li {
        float: none;
        > a {
          padding-top:    10px;
          padding-bottom: 10px;
          /*border-bottom: 1px solid $navbar-default-border;*/
        }
      }
    }

    .navbar-form {
      margin: 0;
      margin-left: -$navbar-padding-horizontal;
      margin-right: -$navbar-padding-horizontal;
      padding: 10px $navbar-padding-horizontal;
      border-bottom: 1px solid $navbar-default-border;
    }

    .navbar-text {
      float: none;
      margin-left: 0;
      margin-right: 0;
    }

    .navbar-left,
    .navbar-right {
      float: none !important;
    }

    .navbar-nav {
      .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid $navbar-default-border;

        > li > a,
        .dropdown-header {
          padding: 5px 15px 5px 25px;
        }

        > li {
          > a {
            line-height: $line-height-computed;
            color: $navbar-default-link-color;

            &:hover, &:focus {
              background-image: none;
            }
          }
        }

        > .active > a {
          background-color: $navbar-default-link-active-bg;
          color: $navbar-default-link-active-color;
        }
      }

      > li > a,
      .dropdown-menu > li > a {
        &:hover, &:focus {
          background-color: $navbar-default-link-hover-bg;
          color: $navbar-default-link-hover-color;
        }
      }

      > li > a {
        padding-left: 35px;
      }
    }

    .dropdown > .dropdown-toggle,
    .dropdown-menu > .dropdown-header {
      background-color: transparent !important;
      color: lighten($navbar-default-color, 15%) !important;
      cursor: default;
      font-size: 0.8em;
      text-transform: uppercase;
      border-bottom: none;
      padding-bottom: 0;
    }

    .dropdown-toggle .caret {
      display: none;
    }

    .dropdown-menu {
      display: block;
    }

    &.navbar-inverse {
      .navbar-collapse,
      .navbar-nav > li > a,
      .navbar-form,
      .navbar-nav .dropdown-menu {
        border-color: $navbar-inverse-border;
      }

      .divider {
        background-color: $navbar-inverse-border;
      }

      .navbar-nav {
        .dropdown-menu > li > a {
          color: $navbar-inverse-link-color;
        }

        .dropdown-menu > .active > a {
          background-color: $navbar-inverse-link-active-bg;
          color: $navbar-inverse-link-active-color;
        }

        > li:not(.active) > a,
        .dropdown-menu > li:not(.active) > a {
          &:hover, &:focus {
            background-color: $navbar-inverse-link-hover-bg;
            color: $navbar-inverse-link-hover-color;
          }
        }
      }

      .dropdown > .dropdown-toggle {
        color: darken($navbar-inverse-color, 15%) !important;
      }
    }
  }
}