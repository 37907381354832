.line-item-modal {
  #update-line-item-btn {
    display: none;
  }
  &.edit-mode {
    #create-line-item-btn {
      display: none;
    }
    #update-line-item-btn {
      display: inline-block;
    }
  }
}

